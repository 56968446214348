import * as actionTypes from "../type/target";

export const fetchTargetSuccess = data => {
  return {
    type: actionTypes.FETCH_TARGETS_SUCCESS,
    targets: data
  };
};

export const fetchTargetFail = error => {
  return {
    type: actionTypes.FETCH_TARGETS_FAIL,
    error: error
  };
};

export const fetchTargetStart = () => {
  return {
    type: actionTypes.FETCH_TARGETS_START
  };
};

export const fetchTarget = () => {
  return {
    type: actionTypes.FETCH_TARGETS
  };
};
