export const FETCH_SOURCE_APPS = 'FETCH_SOURCE_APPS';
export const FETCH_SOURCE_APPS_START = 'FETCH_SOURCE_APPS_START';
export const FETCH_SOURCE_APPS_SUCCESS = 'FETCH_SOURCE_APPS_SUCCESS';
export const FETCH_SOURCE_APPS_FAIL = 'FETCH_SOURCE_APPS_FAIL';




export const GET_SOURCE_APPS = "GET_SOURCE_APPS";
export const DELETE_SOURCE_APPS = "DELETE_SOURCE_APPS";
export const CREATE_SOURCE_APPS = "CREATE_SOURCE_APPS";
export const SET_SOURCE_APPS = "SET_SOURCE_APPS";


