export const FETCH_OPERATORS = "FETCH_OPERATORS";
export const FETCH_OPERATORS_START = "FETCH_OPERATORS_START";
export const FETCH_OPERATORS_SUCCESS = "FETCH_OPERATORS_SUCCESS";
export const FETCH_OPERATORS_FAIL = "FETCH_OPERATORS_FAIL";

export const GET_OPERATORS = "GET_OPERATORS";
export const DELETE_OPERATORS = "DELETE_OPERATORS";



export const CREATE_OPERATORS = "CREATE_OPERATORS";
export const SET_OPERATORS = "SET_OPERATORS";

