import * as actionTypes from "../type/campaign";

export const fetchCampaignSuccess = data => {
  return {
    type: actionTypes.FETCH_CAMPAIGNS_SUCCESS,
    campaigns: data
  };
};

export const fetchCampaignFail = error => {
  return {
    type: actionTypes.FETCH_CAMPAIGNS_FAIL,
    error: error
  };
};

export const fetchCampaignStart = () => {
  return {
    type: actionTypes.FETCH_CAMPAIGNS_START
  };
};

export const fetchCampaign = () => {
  return {
    type: actionTypes.FETCH_CAMPAIGNS
  };
};
