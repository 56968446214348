import { put, call, delay } from "redux-saga/effects";
import axios from "axios";
import * as actions from "../actions/auth";
import { URL } from "../axios/userApi";
export function* logoutSaga(action) {
  yield call([localStorage, "removeItem"], "token");
  yield call([localStorage, "removeItem"], "expirationDate");
  yield call([localStorage, "removeItem"], "userId");
  yield put(actions.logoutSucceed());
}

export function* checkAuthTimeoutSaga(action) {
  yield delay(action.expirationTime * 1000);
  yield put(actions.logout());
}

export function* authUserSaga(action) {
  yield put(actions.authStart());
  const authData = {
    email: action.email,
    password: action.password
  };

  try {
    const {
      data: { userRecord, token, expireIn }
    } = yield axios.post(URL, authData);

    const expirationDate = yield new Date(
      new Date().getTime() + 3000 * 1000
    );

    yield localStorage.setItem("token", token);
    yield localStorage.setItem("expirationDate", expirationDate);
    yield localStorage.setItem("userId", JSON.stringify(userRecord));
    yield put(actions.authSuccess(token, userRecord));
    yield put(actions.checkAuthTimeout(expireIn));
  } catch (error) {
    yield put(actions.authFail("Auth Fail"));
    // yield put(actions.authFail(error.response.data.error));

  }
}

export function* authCheckStateSaga(lastLocation) {
  console.log("Last Location")
  const token = yield localStorage.getItem("token");
  if (!token) {
    yield put(actions.logout());
  } else {
    const expirationDate = yield new Date(
      localStorage.getItem("expirationDate")
    );
    if (expirationDate <= new Date()) {
      yield put(actions.logout());
    } else {
      const userId = yield localStorage.getItem("userId");
      yield put(actions.authSuccess(token, JSON.parse(userId)));
      yield put(
        actions.checkAuthTimeout(
          (expirationDate.getTime() - new Date().getTime()) / 1000
        )
      );
    }
  }
}
