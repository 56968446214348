import { put } from "redux-saga/effects";

import axios from "axios";
import * as actions from "../actions/location";
import { URL } from "../axios/locationApi";

export function* fetchLocationSaga(action) {
  yield put(actions.fetchLocationStart());
  try {
    const res = yield axios.get(URL);
    yield put(actions.fetchLocationSuccess(res.data.response));
  } catch (error) {
    console.log(error)
    yield put(actions.fetchLocationFail("error"));
  }
}



export function* createLocationSaga(action) {
  try {
    const {
      data: { response }
    } = yield axios.post(`${URL}/create`, action.payload);
    yield put(actions.setLocation(response));
  } catch (error) {
    yield put(actions.fetchLocationFail("error"));
  }
}