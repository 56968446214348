import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { persistStore } from "redux-persist";

import { rootReducer } from "../reducer";
import {
  watchAuth,
  watchOperator,
  watchSourceApp,
  watchLocation,
  watchTarget,
  watchAdvertising,
  watchCampaign
} from "../saga/rootSaga";
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
export const persistor = persistStore(store);

sagaMiddleware.run(watchAuth);
sagaMiddleware.run(watchOperator);
sagaMiddleware.run(watchSourceApp);


sagaMiddleware.run(watchLocation);
sagaMiddleware.run(watchTarget);
sagaMiddleware.run(watchAdvertising);
sagaMiddleware.run(watchCampaign);


export default store;
