// /* eslint-disable no-restricted-imports */
// import React, { useState } from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import Select from "react-select";

// import AddIcon from "@material-ui/icons/Add";
// import { Button, MenuItem, InputLabel } from "@material-ui/core";
// import Dialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
// import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
// import DialogTitle from "@material-ui/core/DialogTitle";
// import IconButton from "@material-ui/core/IconButton";
// import PropTypes from "prop-types";
// import TextField from "@material-ui/core/TextField";
// import Tooltip from "@material-ui/core/Tooltip";
// import Fab from "@material-ui/core/Fab";
// import InputField from "../../content/Input/input";
// const useStyles = makeStyles(theme => ({
//   paper: { minWidth: "500px" },

//   a: {
//     marginTop: "20px"
//   },
//   extendedIcon: {
//     marginRight: theme.spacing(1)
//   }
// }));

// const options = [
//   { value: "country", label: "Country Code" },
//   { value: "region", label: "Region Code" },
//   { value: "town", label: 'Town Code"' }
// ];


// const AddUserDialog = props => {
//   const [data, setData] = useState({});
//   const [code, setCode] = useState(null);
//   const classes = useStyles();

//   const { addUserHandler, fields } = props;
//   const [open, setOpen] = React.useState(false);

//   const [switchState, setSwitchState] = React.useState({
//     addMultiple: false
//   });

//   const handleSwitchChange = name => event => {
//     setSwitchState({ ...switchState, [name]: event.target.checked });
//   };

//   const resetSwitch = () => {
//     setSwitchState({ addMultiple: false });
//   };

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//     resetSwitch();
//   };

//   const handleAdd = () => {
//     if (
//       data.country_code &&
//       data.region_code === undefined &&
//       data.town_code === undefined
//     ) {
//       data["type"] = "country";
//     } else if (
//       data.country_code &&
//       data.region_code &&
//       data.town_code === undefined
//     ) {
//       data["type"] = "region";
//     } else {
//       data["type"] = "town";
//     }
//     console.log(data);
//     addUserHandler(data);
//     setData("");
//     setCode(null);
//     switchState.addMultiple ? setOpen(true) : setOpen(false);
//   };

//   const handleChange = name => ({ target: { value } }) => {
//     setData({ ...data, [name]: value });
//   };
//   const handleCodeChnage = value => {
//     setCode(value);
//   };

//   console.log("Value", code);
//   return (
//     <div>
//       <Tooltip title="Add">
//         {/* <IconButton aria-label="add" onClick={handleClickOpen}>
//           <IconButton aria-label="add" onClick={handleClickOpen}> */}
//         {/* <AddIcon /> */}
//         <Fab
//           onClick={handleClickOpen}
//           variant="extended"
//           size="small"
//           color="primary"
//           aria-label="Add"
//           className={classes.margin}
//         >
//           <AddIcon className={classes.extendedIcon} />
//           Add
//         </Fab>
//         {/* </IconButton>
//         </IconButton> */}
//       </Tooltip>
//       <Dialog
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="form-dialog-title"
//         classes={{ paper: classes.paper }}
//       >
//         <DialogTitle id="form-dialog-title">Add </DialogTitle>
//         <DialogContent style={{ height: "450px" }}>
//           {fields.map(i => {
//             return (
//               <TextField
//                 margin="dense"
//                 label={i.label}
//                 type={i.type}
//                 fullWidth
//                 variant="outlined"
//                 onChange={handleChange(i.name)}
//               />
//             );
//           })}

//           <div style={{ margin: "1rem 0" }}>
//             <label>{"Name"}</label>
//             <TextField
//               margin="dense"
//               label="Name"
//               type="text"
//               fullWidth
//               variant="outlined"
//               onChange={handleChange("name")}
//             />
//           </div>
//           <div style={{ margin: "1rem 0" }}>
//             <label>{"Lng"}</label>
//             <TextField
//               margin="dense"
//               label="Lng"
//               type="number"
//               fullWidth
//               variant="outlined"
//               onChange={handleChange("lng")}
//             />
//           </div>
//           <div style={{ margin: "1rem 0" }}>
//             <label>{"Lng"}</label>
//             <TextField
//               margin="dense"
//               label="Lat"
//               type="number"
//               fullWidth
//               variant="outlined"
//               onChange={handleChange("lat")}
//             />
//           </div>

//           <div className={classes.a}>
//             <Select
//               value={code}
//               onChange={handleCodeChnage}
//               options={options}
//             />
//           </div>

//           {code !== null && code.value === "country" && (
//             <div style={{ margin: "1rem 0" }}>
//               <label>{"Country"}</label>
//               <TextField
//                 margin="dense"
//                 label="Country"
//                 type="text"
//                 fullWidth
//                 variant="outlined"
//                 onChange={handleChange("country_code")}
//               />
//             </div>
//           )}

//           {code !== null && code.value === "region" && (
//             <>
//               <div style={{ margin: "1rem 0" }}>
//                 <label>{"Country"}</label>
//                 <TextField
//                   margin="dense"
//                   label="Country"
//                   type="text"
//                   fullWidth
//                   variant="outlined"
//                   onChange={handleChange("country_code")}
//                 />
//               </div>
//               <div style={{ margin: "1rem 0" }}>
//                 <label>{"Region"}</label>
//                 <TextField
//                   margin="dense"
//                   label="Region"
//                   type="text"
//                   fullWidth
//                   variant="outlined"
//                   onChange={handleChange("region_code")}
//                 />
//               </div>
//             </>
//           )}
//           {code !== null && code.value === "town" && (
//             <>
//               <div style={{ margin: "1rem 0" }}>
//                 <label>{"Country"}</label>
//                 <TextField
//                   margin="dense"
//                   label="Country"
//                   type="text"
//                   fullWidth
//                   variant="outlined"
//                   onChange={handleChange("country_code")}
//                 />
//               </div>
//               <div style={{ margin: "1rem 0" }}>
//                 <label>{"Region"}</label>
//                 <TextField
//                   margin="dense"
//                   label="Region"
//                   type="text"
//                   fullWidth
//                   variant="outlined"
//                   onChange={handleChange("region_code")}
//                 />
//               </div>
//               <div style={{ margin: "1rem 0" }}>
//                 <label>{"Town"}</label>
//                 <TextField
//                   margin="dense"
//                   label="Town"
//                   type="text"
//                   fullWidth
//                   variant="outlined"
//                   onChange={handleChange("town_code")}
//                 />
//               </div>
//             </>
//           )}
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose} color="primary">
//             Cancel
//           </Button>
//           <Button onClick={handleAdd} color="primary">
//             Add
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </div>
//   );
// };

// AddUserDialog.propTypes = {
//   addUserHandler: PropTypes.func.isRequired
// };

// export default AddUserDialog;


/* eslint-disable no-restricted-imports */
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";

import AddIcon from "@material-ui/icons/Add";
import { Button, MenuItem, InputLabel } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
// import InputField from "../../content/Input/input";
const useStyles = makeStyles(theme => ({
  paper: { minWidth: "500px" },

  a: {
    marginTop: "20px"
  },
  extendedIcon: {
    marginRight: theme.spacing(1)
  }
}));

const options = [
  { value: "country", label: "Country Code" },
  { value: "region", label: "Region Code" },
  { value: "town", label: 'Town Code"' }
];

const initialUser = {
  name: "",
  email: "",
  password: "",
  cpassword: ""
};

const AddUserDialog = props => {
  const [data, setData] = useState({});
  const [code, setCode] = useState(null);
  const [not, setNot] = useState(null);
  const classes = useStyles();

  const { addUserHandler, fields } = props;
  const [open, setOpen] = React.useState(false);

  const [switchState, setSwitchState] = React.useState({
    addMultiple: false
  });

  const handleSwitchChange = name => event => {
    setSwitchState({ ...switchState, [name]: event.target.checked });
  };

  const resetSwitch = () => {
    setSwitchState({ addMultiple: false });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const colourOptions = [
    { value: "myanmar_not_in_yangon", label: "Myanmar_Not_In_yangon" },
    {
      value: "othercountry_not_in_myanmar",
      label: "Othercountry_Not_In_Myanmar"
    }
  ];

  const handleClose = () => {
    setOpen(false);
    resetSwitch();
  };

  const handleAdd = () => {
    if (
      data.country_code &&
      data.region_code === undefined &&
      data.town_code === undefined
    ) {
      data["type"] = "country";
    } else if (
      data.country_code &&
      data.region_code &&
      data.town_code === undefined
    ) {
      data["type"] = "region";
    } else {
      data["type"] = "town";
    }
    addUserHandler({ ...data, not: not !== null ? not.value : null });
    console.log();
    setData("");
    setCode(null);
    switchState.addMultiple ? setOpen(true) : setOpen(false);
  };

  const handleChange = name => ({ target: { value } }) => {
    setData({ ...data, [name]: value });
  };
  const handleCodeChnage = value => {
    setCode(value);
  };

  const handleNotChange = (newValue, actionMeta) => {
    console.group("Value Changed");
    console.log(newValue);
    setNot(newValue);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  };
  const handleNotInputChange = (inputValue, actionMeta) => {
    console.group("Input Changed");
    console.log(inputValue);
    console.log(`action: ${actionMeta.action}`);
    console.groupEnd();
  };

  console.log("Value", code);
  return (
    <div>
      <Tooltip title="Add">
        {/* <IconButton aria-label="add" onClick={handleClickOpen}>
          <IconButton aria-label="add" onClick={handleClickOpen}> */}
        {/* <AddIcon /> */}
        <Fab
          onClick={handleClickOpen}
          variant="extended"
          size="small"
          color="primary"
          aria-label="Add"
          className={classes.margin}
        >
          <AddIcon className={classes.extendedIcon} />
          Add
        </Fab>
        {/* </IconButton>
        </IconButton> */}
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.paper }}
      >
        <DialogTitle id="form-dialog-title">Add </DialogTitle>
        <DialogContent style={{ height: "450px" }}>
          <div style={{ margin: "1rem 0" }}>
            <label>{"Name"}</label>
            <TextField
              margin="dense"
              label="Name"
              type="text"
              fullWidth
              variant="outlined"
              onChange={handleChange("name")}
            />
          </div>
          <div style={{ margin: "1rem 0" }}>
            <label>{"Lng"}</label>
            <TextField
              margin="dense"
              label="Lng"
              type="number"
              fullWidth
              variant="outlined"
              onChange={handleChange("lng")}
            />
          </div>
          <div style={{ margin: "1rem 0" }}>
            <label>{"Lat"}</label>
            <TextField
              margin="dense"
              label="Lat"
              type="number"
              fullWidth
              variant="outlined"
              onChange={handleChange("lat")}
            />
          </div>
          <div style={{ margin: "1rem 0" }}>
            <label>{"Not"}</label>
            <CreatableSelect
              isClearable={true}
              onChange={handleNotChange}
              onInputChange={handleNotInputChange}
              options={colourOptions}
            />
          </div>

          <div className={classes.a}>
            <Select
              isClearable={true}
              value={code}
              onChange={handleCodeChnage}
              options={options}
            />
          </div>

          {code !== null && code.value === "country" && (
            <div style={{ margin: "1rem 0" }}>
              <label>{"Country"}</label>
              <TextField
                margin="dense"
                label="Country"
                type="text"
                fullWidth
                variant="outlined"
                onChange={handleChange("country_code")}
              />
            </div>
          )}

          {code !== null && code.value === "region" && (
            <>
              <div style={{ margin: "1rem 0" }}>
                <label>{"Country"}</label>
                <TextField
                  margin="dense"
                  label="Country"
                  type="text"
                  fullWidth
                  variant="outlined"
                  onChange={handleChange("country_code")}
                />
              </div>
              <div style={{ margin: "1rem 0" }}>
                <label>{"Region"}</label>
                <TextField
                  margin="dense"
                  label="Region"
                  type="text"
                  fullWidth
                  variant="outlined"
                  onChange={handleChange("region_code")}
                />
              </div>
            </>
          )}
          {code !== null && code.value === "town" && (
            <>
              <div style={{ margin: "1rem 0" }}>
                <label>{"Country"}</label>
                <TextField
                  margin="dense"
                  label="Country"
                  type="text"
                  fullWidth
                  variant="outlined"
                  onChange={handleChange("country_code")}
                />
              </div>
              <div style={{ margin: "1rem 0" }}>
                <label>{"Region"}</label>
                <TextField
                  margin="dense"
                  label="Region"
                  type="text"
                  fullWidth
                  variant="outlined"
                  onChange={handleChange("region_code")}
                />
              </div>
              <div style={{ margin: "1rem 0" }}>
                <label>{"Town"}</label>
                <TextField
                  margin="dense"
                  label="Town"
                  type="text"
                  fullWidth
                  variant="outlined"
                  onChange={handleChange("town_code")}
                />
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            disabled={code === null ? true : false}
            onClick={handleAdd}
            color="primary"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

AddUserDialog.propTypes = {
  addUserHandler: PropTypes.func.isRequired
};

export default AddUserDialog;

