import * as actionTypes from "../type/advertising";
import { updateObject } from "../shared/utility";

const initialState = {
  advertisings: [],
  loading: true,
  error: null
};

const fetchAdvertisingStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchAdvertisingSuccess = (state, action) => {
  return updateObject(state, {
    advertisings: action.advertisings,
    loading: false
  });
};

const fetchAdvertisingFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ADVERTISINGS_START:
      return fetchAdvertisingStart(state, action);
    case actionTypes.FETCH_ADVERTISINGS_SUCCESS:
      return fetchAdvertisingSuccess(state, action);
    case actionTypes.FETCH_ADVERTISINGS_FAIL:
      return fetchAdvertisingFail(state, action);
    default:
      return state;
  }
};

export default reducer;
