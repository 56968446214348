import { put } from "redux-saga/effects";

import axios from "axios";
import * as actions from "../actions/advertising";
import { URL } from "../axios/adsApi";

export function* fetchAdvertisingSaga(action) {
  yield put(actions.fetchAdvertisingStart());
  try {
    const { data } = yield axios.get(URL);
    console.log(data);
    yield put(actions.fetchAdvertisingSuccess(data));
  } catch (error) {
    yield put(actions.fetchAdvertisingFail("error"));
  }
}
