import React, { Component } from "react";
import { logout } from "../../actions/auth";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { LayoutSplashScreen } from "../../../_metronic";

// class Logout extends Component {
//   componentDidMount() {
//     this.props.logout();
//   }

//   render() {
//     const { hasAuthToken } = this.props;

//     return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth" />;
//   }
// }

// export default connect(
//   ({ auth }) => ({ hasAuthToken: Boolean(auth.token) }),
//   logout()
// )(Logout);



class Logout extends Component {
    componentDidMount () {
        this.props.onLogout();
    }

    render () {
      const {token} = this.props;
        return  Boolean(token) ?  <LayoutSplashScreen /> : <Redirect to="/auth"/>;
    }
}

const mapStateToProps = state => ({
   token : state.auth.token
});

const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => dispatch(logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);