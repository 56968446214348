import { actionTypes } from "../type/userType";
import { updateObject } from "../shared/utility";
import * as routerHelpers from "../router/RouterHelpers";

const initialState = {
  token: null,
  user: null,
  error: null,
  loading: false,
  authRedirectPath: "/"
};

const authStart = (state, action) => {
  return updateObject(state, { error: null, loading: true });
};

const authSuccess = (state, action) => {
  const getItem = localStorage.getItem("ads-lastLocation") === "" ? "" : JSON.parse(localStorage.getItem("ads-lastLocation"))
  // if (getItem) {
  if (action.user.role === 'advertiser') {
    routerHelpers.forgotLastLocation()
    routerHelpers.saveLastLocation({
      pathname: "/campaign/list"
    })
  }

  // if (action.user.role === 'advertiser' && getItem.includes("/account/advertiser")) {
  //   routerHelpers.forgotLastLocation
  //   routerHelpers.saveLastLocation({
  //     pathname: "/campaign/list"
  //   })
  // }


  // }

  return updateObject(state, {
    token: action.token,
    user: action.user,
    error: null,
    loading: false
  });
};

const authFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const authLogout = (state, action) => {
  return updateObject(state, { token: null, user: null });
};

const setAuthRedirectPath = (state, action) => {
  return updateObject(state, { authRedirectPath: action.path });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      routerHelpers.forgotLastLocation()
      return authLogout(state, action);
    case actionTypes.SET_AUTH_REDIRECT_PATH:
      return setAuthRedirectPath(state, action);
    default:
      return state;
  }
};

export default reducer;
