import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Builder from "./Builder";
import Dashboard from "./Dashboard";
// import Advertisor from "./Advertisor";
import Operator from "./Operator";
import SourceApp from "./SourceApp";
import DocsPage from "./docs/DocsPage";

import { LayoutSplashScreen } from "../../../_metronic";

const Target = lazy(() => import("./Target/index"));
const Location = lazy(() => import("./Location/index"))

const Campaign = lazy(() => import("./Campaign/index"));
const Advertising = lazy(() => import("./Advertising/index"));
const Account = lazy(() => import("./Account/index"));

export default function HomePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <Route path="/builder" component={Builder} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/account" component={Account} />
        <Route path="/operator" component={Operator} />

        <Route path="/source" component={SourceApp} />
        <Route path="/locations" component={Location} />

        <Route path="/campaign" component={Campaign} />
        <Route path="/ads" component={Advertising} />

        <Route path="/docs" component={DocsPage} />
        <Route path="/target" component={Target} />

        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
