import * as actionTypes from "../type/advertising";

export const fetchAdvertisingSuccess = data => {
  return {
    type: actionTypes.FETCH_ADVERTISINGS_SUCCESS,
    advertisings: data
  };
};

export const fetchAdvertisingFail = error => {
  return {
    type: actionTypes.FETCH_ADVERTISINGS_FAIL,
    error: error
  };
};

export const fetchAdvertisingStart = () => {
  return {
    type: actionTypes.FETCH_ADVERTISINGS_START
  };
};

export const fetchAdvertising = () => {
  return {
    type: actionTypes.FETCH_ADVERTISINGS
  };
};
