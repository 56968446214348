import React, { useEffect, useState, useMemo, useCallback } from "react";
import { connect } from "react-redux";
import { format } from "date-fns";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as advertisorAction from "../../actions/advertisorAction";
import EnhancedTable from "../../partials/content/DataTable/EnhancedTable";
import Editable from "../../partials/content/Table/Editable";
import {
  fetchSourceApp,
  createSourceApp,
  getSourceApp
} from "../../actions/source_app";
import { getApi, deleteApi, editApi, createApi } from "../../axios/sourceApi";
import {
  Portlet,
  PortletBody,
  PortletHeader
} from "../../partials/content/Portlet";
function SourceApp(props) {
  const {
    onFetchSourceApp,
    source_apps,
    loading,
    createSource,
    getSourceApp
  } = props;
  const [data, setData] = useState();
  // const [loading, setLoading] = useState(true);

  const fields = React.useMemo(() => [
    { label: "Name", type: "text", name: "name", value: "" },
    { label: "Code", type: "text", name: "code", value: "" }
  ]);
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name"
      },
      {
        Header: "Key",
        accessor: "code"
      },

      {
        Header: "Active",
        accessor: "active"
      },

      {
        Header: "CreatedAt",
        accessor: "createdAt",
        Cell: ({ row: { original } }) => {
          return original.created_at === undefined
            ? format(new Date(original.createdAt), "dd-MM-yyyy'T'HH:mm:a")
            : format(new Date(original.created_at), "dd-MM-yyyy'T'HH:mm:a");
        }
      }
    ],
    []
  );

  const fetchData = async () => {
    try {
      let res = await getApi();
      setData(res.data);
    } catch (error) {
      localStorage.setItem("persist:BaganAds", "");
      window.location.href = "/auth/login";
    }
  };

  useEffect(() => {
    onFetchSourceApp();
  }, []);

  const deleteSource = id => {
    deleteApi(id);
  };

  const create = data => {
    createSource(data);
  };

  const updateSource = (id, data) => {
    console.log(id, data)
    editApi(id, data);
  };

  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    console.log("Value", value);
    source_apps.map((row, index) => {
      if (index === rowIndex) {
        let data = {
          ...source_apps[rowIndex],
          [columnId]: value
        };

        console.log("columnId", data);

        updateSource(data._id, data);

        return {
          ...source_apps[rowIndex],
          [columnId]: value
        };
      }
      return row;
    });
  };
  return (
    <>
      <Portlet fluidHeight={true}>
        <PortletHeader title="SourceApp" />

        <PortletBody>
          {loading ? (
            <div style={{ margin: "0 auto" }}>
              <CircularProgress />
            </div>
          ) : (
            <Editable
              fields={fields}
              columns={columns}
              data={source_apps}
              deleteData={deleteSource}
              createData={create}
              setData={getSourceApp}
              updateMyData={updateMyData}
              skipPageReset={skipPageReset}
            />
          )}
        </PortletBody>
      </Portlet>
    </>
  );
}

const mapStateToProps = state => ({
  source_apps: state.source_app.source_apps
});

const mapDispatchToProps = dispatch => {
  return {
    onFetchSourceApp: () => dispatch(fetchSourceApp()),
    getSourceApp: data => dispatch(getSourceApp(data)),
    createSource: data => dispatch(createSourceApp(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SourceApp);
