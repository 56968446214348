import { combineReducers } from "redux";

import authReducer from "../reducer/auth";
import operatorReducer from "../reducer/operator";
import sourceAppReducer from "../reducer/source_app";

import locationReducer from "../reducer/location";
import targetReducer from "../reducer/target";
import advertisingReducer from "../reducer/advertising";
import campaignReducer from "../reducer/campaign";

import * as advertisors from "../reducer/advertisorReducer";
import { metronic } from "../../_metronic";

export const rootReducer = combineReducers({
  operators: operatorReducer,
  source_app: sourceAppReducer,
  location: locationReducer,
  target: targetReducer,
  advertising: advertisingReducer,
  campaign: campaignReducer,
  auth: authReducer,
  advertisor: advertisors.reducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer
});
