import { put } from "redux-saga/effects";

import axios from "axios";
import * as actions from "../actions/campaign";
import { URL } from "../axios/campaignApi";

export function* fetchCampaignSaga(action) {
  yield put(actions.fetchCampaignStart());
  try {
    const { data } = yield axios.get(URL);
    console.log(data)
    yield put(actions.fetchCampaignSuccess(data));
  } catch (error) {
    yield put(actions.fetchCampaignFail("error"));
  }
}
