import * as actionTypes from "../type/target";
import { updateObject } from "../shared/utility";

const initialState = {
  targets: [],
  loading: true,
  error : null
};

const fetchTargetStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchTargetSuccess = (state, action) => {
  return updateObject(state, {
    targets: action.targets,
    loading: false
  });
};

const fetchTargetFail = (state, action) => {
  return updateObject(state, { loading: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_TARGETS_START:
      return fetchTargetStart(state, action);
    case actionTypes.FETCH_TARGETS_SUCCESS:
      return fetchTargetSuccess(state, action);
    case actionTypes.FETCH_TARGETS_FAIL:
      return fetchTargetFail(state, action);
    default:
      return state;
  }
};

export default reducer;
