import { put } from "redux-saga/effects";

import axios from "axios";
import * as actions from "../actions/operator";
import { URL } from "../axios/operatorApi";

export function* fetchOperatorSaga(action) {
  yield put(actions.fetchOpeartorsStart());
  try {
    const { data } = yield axios.get(URL);
    yield put(actions.fetchOperatorSuccess(data));
  } catch (error) {
    yield put(actions.fetchOpeartorsFail("error"));
  }
}

export function* createOperatorSaga(action) {
  try {
    const {
      data: { response }
    } = yield axios.post(`${URL}/create`, action.payload);
    yield put(actions.setOperator(response));
  } catch (error) {
    yield put(actions.fetchOpeartorsFail("error"));
  }
}

// export function* name(action) {
//   try {
//     const res = yield axios.post(`${URL}/create`, action.payload);
//     if (res) {
//       yield put(actions.setOperator(response));

//     }
//   } catch (error) {
//     yield put(actions.fetchOpeartorsFail("error"));
//   }
// }
