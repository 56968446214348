/* eslint-disable no-restricted-imports */
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Select from "react-select";

import AddIcon from "@material-ui/icons/Add";
import { Button, MenuItem, InputLabel } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: "100%"
  },
  a: {
    marginTop: "20px"
  }
}));

const options = [
  { value: "country", label: "Country Code" },
  { value: "region", label: "Region Code" },
  { value: "town", label: 'Town Code"' }
];

const initialUser = {
  name: "",
  email: "",
  password: "",
  cpassword: ""
};

const AddUserDialog = props => {
  const [data, setData] = useState({});
  const [code, setCode] = useState(null);
  const classes = useStyles();

  const { addUserHandler, fields } = props;
  const [open, setOpen] = React.useState(false);

  const [switchState, setSwitchState] = React.useState({
    addMultiple: false
  });

  const handleSwitchChange = name => event => {
    setSwitchState({ ...switchState, [name]: event.target.checked });
  };

  const resetSwitch = () => {
    setSwitchState({ addMultiple: false });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    resetSwitch();
  };

  const handleAdd = () => {
    if (
      data.country_code &&
      data.region_code === undefined &&
      data.town_code === undefined
    ) {
      data["type"] = "country";
    } else if (
      data.country_code &&
      data.region_code &&
      data.town_code === undefined
    ) {
      data["type"] = "region";
    } else {
      data["type"] = "town";
    }
    console.log(data)
    addUserHandler(data);
    setData("");
    setCode(null);
    switchState.addMultiple ? setOpen(true) : setOpen(false);
  };

  const handleChange = name => ({ target: { value } }) => {
    setData({ ...data, [name]: value });
  };
  const handleCodeChnage = value => {
    setCode(value);
  };

  console.log("Value", code);
  return (
    <div>
      <Tooltip title="Add">
        <IconButton aria-label="add" onClick={handleClickOpen}>
          <IconButton aria-label="add" onClick={handleClickOpen}>
            {/* <AddIcon /> */}
            <Fab
              variant="extended"
              size="medium"
              color="primary"
              aria-label="Add"
              className={classes.margin}
            >
              <AddIcon className={classes.extendedIcon} />
              Add
            </Fab>
          </IconButton>
        </IconButton>
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add </DialogTitle>
        <DialogContent style = {{height : '450px'}}>
          <DialogContentText>Add Data</DialogContentText>

          {fields.map(i => {
            return (
              <TextField
                margin="dense"
                label={i.label}
                type={i.type}
                fullWidth
                onChange={handleChange(i.name)}
              />
            );
          })}

          <TextField
            margin="dense"
            label="Name"
            type="text"
            fullWidth
            onChange={handleChange("name")}
          />

          <TextField
            margin="dense"
            label="Lng"
            type="number"
            fullWidth
            onChange={handleChange("lng")}
          />

          <TextField
            margin="dense"
            label="Lat"
            type="number"
            fullWidth
            onChange={handleChange("lat")}
          />

          <div className={classes.a}>
            <Select
              value={code}
              onChange={handleCodeChnage}
              options={options}
            />
          </div>

          {code !== null && code.value === "country" && (
            <TextField
              margin="dense"
              label="Country"
              type="text"
              fullWidth
              onChange={handleChange("country_code")}
            />
          )}

          {code !== null && code.value === "region" && (
            <>
              <TextField
                margin="dense"
                label="Country"
                type="text"
                fullWidth
                onChange={handleChange("country_code")}
              />
              <TextField
                margin="dense"
                label="Region"
                type="text"
                fullWidth
                onChange={handleChange("region_code")}
              />
            </>
          )}
          {code !== null && code.value === "town" && (
            <>
              <TextField
                margin="dense"
                label="Country"
                type="text"
                fullWidth
                onChange={handleChange("country_code")}
              />
              <TextField
                margin="dense"
                label="Region"
                type="text"
                fullWidth
                onChange={handleChange("region_code")}
              />
              <TextField
                margin="dense"
                label="Town"
                type="text"
                fullWidth
                onChange={handleChange("town_code")}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleAdd} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

AddUserDialog.propTypes = {
  addUserHandler: PropTypes.func.isRequired
};

export default AddUserDialog;
