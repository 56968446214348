import { GET_ADVERTISORS, RECEIVED_ADVERTISOR } from '../type/advertisorType'

export const reducer = (state = {}, action) => {
    switch (action.type) {
        case GET_ADVERTISORS:
            console.log("Hi from advertisor")
            return { ...state };
        case RECEIVED_ADVERTISOR:
            console.log("Reducer from", action)
            return { ...state, advertisors: action.json }
        default:
            return state;
    }
};

