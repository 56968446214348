import axios from 'axios';

import { dev_endpont, prodcution_endpoint } from "../../endpoint";

export const URL =
  process.env.NODE_ENV === "development"
    ? `${dev_endpont}/source`
    : `${prodcution_endpoint}/source`;


export function getApi() {
    return axios.get(URL)
}


export function deleteApi(id) {
    return axios.delete(`${URL}/delete/${id}`)
}

export function editApi(id, data) {
    return axios.patch(`${URL}/update/${id}`, data)
}

export function createApi(data) {
    return axios.post(`${URL}/create`, data)

}