import { takeEvery, all, takeLatest } from "redux-saga/effects";

import { actionTypes } from "../type/userType";
import * as operatorType from "../type/operator";
import * as sourceAppType from "../type/source_app";
import { FETCH_LOCATIONS, CREATE_LOCATIONS } from "../type/location";
import { FETCH_TARGETS } from "../type/target";
import { FETCH_ADVERTISINGS } from "../type/advertising";
import { FETCH_CAMPAIGNS } from "../type/campaign";

import {
  logoutSaga,
  checkAuthTimeoutSaga,
  authUserSaga,
  authCheckStateSaga
} from "./auth";

import { fetchOperatorSaga, createOperatorSaga } from "./operator";
import { fetchSourceAppSaga, createSourceAppSaga } from "./source_app";
import { fetchLocationSaga, createLocationSaga } from "./location";
import { fetchTargetSaga } from "./target";
import { fetchAdvertisingSaga } from "./advertising";
import { fetchCampaignSaga } from "./campaign";

export function* watchAuth() {
  yield all([
    takeEvery(actionTypes.AUTH_CHECK_TIMEOUT, checkAuthTimeoutSaga),
    takeEvery(actionTypes.AUTH_INITIATE_LOGOUT, logoutSaga),
    takeEvery(actionTypes.AUTH_USER, authUserSaga),
    takeEvery(actionTypes.AUTH_CHECK_STATE, authCheckStateSaga)
  ]);
}

// export function* watchBurgerBuilder() {
//   yield takeEvery(actionTypes.INIT_INGREDIENTS, initIngredientsSaga);
// }

export function* watchOperator() {
  yield takeEvery(operatorType.FETCH_OPERATORS, fetchOperatorSaga);
  yield takeLatest(operatorType.CREATE_OPERATORS, createOperatorSaga);
}

export function* watchSourceApp() {
  yield takeEvery(sourceAppType.FETCH_SOURCE_APPS, fetchSourceAppSaga);
  yield takeLatest(sourceAppType.CREATE_SOURCE_APPS, createSourceAppSaga);
}

export function* watchLocation() {
  yield takeEvery(FETCH_LOCATIONS, fetchLocationSaga);
  yield takeLatest(CREATE_LOCATIONS, createLocationSaga);
}

export function* watchTarget() {
  yield takeEvery(FETCH_TARGETS, fetchTargetSaga);
}

export function* watchAdvertising() {
  yield takeEvery(FETCH_ADVERTISINGS, fetchAdvertisingSaga);
}

export function* watchCampaign() {
  yield takeEvery(FETCH_CAMPAIGNS, fetchCampaignSaga);
}
