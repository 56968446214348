import * as actionTypes from "../type/operator";
import { updateObject } from "../shared/utility";
import { actions } from "../actions/advertisorAction";

const initialState = {
  operators: [],
  loading: true,
  error: null
};

const fetchOpeartorsStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchOpeartorsSuccess = (state, action) => {
  return updateObject(state, {
    operators: action.operators,
    loading: false
  });
};

const fetchOpeartorsFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const getOperators = (state, action) => {
  return updateObject(state, { operators: action.payload });
};

const setOperators = (state, action) => {
  console.log(state);
  return updateObject(state, {
    operators: [action.payload].concat(state.operators)
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_OPERATORS_START:
      return fetchOpeartorsStart(state, action);
    case actionTypes.FETCH_OPERATORS_SUCCESS:
      return fetchOpeartorsSuccess(state, action);
    case actionTypes.FETCH_OPERATORS_FAIL:
      return fetchOpeartorsFail(state, action);
    case actionTypes.GET_OPERATORS:
      return getOperators(state, action);
    case actionTypes.SET_OPERATORS:
      return setOperators(state, action);
    default:
      return state;
  }
};

export default reducer;
