import * as actionTypes from "../type/location";

export const fetchLocationSuccess = data => {
  return {
    type: actionTypes.FETCH_LOCATIONS_SUCCESS,
    locations: data
  };
};

export const fetchLocationFail = error => {
  return {
    type: actionTypes.FETCH_LOCATIONS_FAIL,
    error: error
  };
};

export const fetchLocationStart = () => {
  return {
    type: actionTypes.FETCH_LOCATIONS_START
  };
};

export const fetchLocation = () => {
  return {
    type: actionTypes.FETCH_LOCATIONS
  };
};

export const createLocation = value => {
  return {
    type: actionTypes.CREATE_LOCATIONS,
    payload: value
  };
};

export const setLocation = value => {
  return {
    type: actionTypes.SET_LOCATIONS,
    payload: value
  };
};

export const getLocation = value => {
  return {
    type: actionTypes.GET_LOCATIONS,
    payload: value
  };
};
