import { put } from "redux-saga/effects";

import axios from "axios";
import * as actions from "../actions/source_app";
import { URL } from "../axios/sourceApi";

export function* fetchSourceAppSaga(action) {
  yield put(actions.fetchSourceAppStart());
  try {
    const { data } = yield axios.get(URL);
    console.log(data)
    yield put(actions.fetchSoureAppSuccess(data));
  } catch (error) {
    yield put(actions.fetchSourceAppFail("error"));
  }
}


export function* createSourceAppSaga(action) {
  try {
    const {
      data: { response }
    } = yield axios.post(`${URL}/create`, action.payload);
    yield put(actions.setSourceApp(response));
  } catch (error) {
    yield put(actions.fetchSourceAppFail("error"));
  }
}
