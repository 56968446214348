import * as actionTypes from "../type/operator";

export const fetchOperatorSuccess = data => {
  return {
    type: actionTypes.FETCH_OPERATORS_SUCCESS,
    operators: data
  };
};

export const fetchOpeartorsFail = error => {
  return {
    type: actionTypes.FETCH_OPERATORS_FAIL,
    error: error
  };
};

export const fetchOpeartorsStart = () => {
  return {
    type: actionTypes.FETCH_OPERATORS_START
  };
};

export const fetchOpeartors = () => {
  return {
    type: actionTypes.FETCH_OPERATORS
  };
};

export const getOperators = data => {
  return {
    type: actionTypes.GET_OPERATORS,
    payload: data
  };
};

export const deleteOpearator = id => {
  return {
    type: actionTypes.DELETE_OPERATORS,
    payload: id
  };
};

export const createOperator = value => {
  return {
    type: actionTypes.CREATE_OPERATORS,
    payload: value
  };
};



export const setOperator = value => {
  return {
    type : actionTypes.SET_OPERATORS,
    payload : value
  }
}