import * as actionTypes from "../type/location";
import { updateObject } from "../shared/utility";

const initialState = {
  locations: [],
  loading: true,
  error: null
};

const fetchLocationStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchLocationSuccess = (state, action) => {
  return updateObject(state, {
    locations: action.locations,
    loading: false
  });
};

const fetchLocationFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const setLocation = (state, action) => {
  return updateObject(state, {
    locations: [action.payload].concat(state.locations)
  });
};

const getLocation = (state, action) => {
  return updateObject(state, {
    locations: action.payload
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_LOCATIONS_START:
      return fetchLocationStart(state, action);
    case actionTypes.FETCH_LOCATIONS_SUCCESS:
      return fetchLocationSuccess(state, action);
    case actionTypes.FETCH_LOCATIONS_FAIL:
      return fetchLocationFail(state, action);
    case actionTypes.SET_LOCATIONS:
      return setLocation(state, action);
    case actionTypes.GET_LOCATIONS:
      return getLocation(state, action);
    default:
      return state;
  }
};

export default reducer;
