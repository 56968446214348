import axios from "axios";

import { dev_endpont, prodcution_endpoint } from "../../endpoint";

export const URL =
  process.env.NODE_ENV === "development"
    ? `${dev_endpont}/auth/login`
    : `${prodcution_endpoint}/auth/login`;

export const AUTH_URL =
  process.env.NODE_ENV === "development"
    ? `${dev_endpont}/auth/user`
    : `${prodcution_endpoint}/auth/user`;

// export const LOGIN_URL = "api/auth/login";
// export const REGISTER_URL = "api/auth/register";
// export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";

// export const ME_URL = "http://localhost:5000/api/auth/login";
// export const AUTH_URL = "http://localhost:5000/api/auth/user";



export function login(email, password) {
  return axios.post(URL, { email, password });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(AUTH_URL);
}


