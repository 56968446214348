import * as actionTypes from "../type/source_app";

export const fetchSoureAppSuccess = data => {
  return {
    type: actionTypes.FETCH_SOURCE_APPS_SUCCESS,
    source_apps: data
  };
};

export const fetchSourceAppFail = error => {
  return {
    type: actionTypes.FETCH_SOURCE_APPS_FAIL,
    error: error
  };
};

export const fetchSourceAppStart = () => {
  return {
    type: actionTypes.FETCH_SOURCE_APPS_START
  };
};

export const fetchSourceApp = () => {
  return {
    type: actionTypes.FETCH_SOURCE_APPS
  };
};

export const createSourceApp = value => {
  return {
    type: actionTypes.CREATE_SOURCE_APPS,
    payload: value
  };
};

export const setSourceApp = value => {
  return {
    type: actionTypes.SET_SOURCE_APPS,
    payload: value
  };
};

export const getSourceApp = value => {
  return {
    type: actionTypes.GET_SOURCE_APPS,
    payload: value
  };
};
