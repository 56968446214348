import React, { useEffect, useMemo, useRef } from "react";
import { Chart } from "chart.js";
import { useSelector } from "react-redux";
import { metronic } from "../../_metronic";
import moment from "moment";


export default function OrderStatisticsChart({
  total,
  label,
  backgroundColor,
  borderColor,
  xAxis
}) {
  const ref1 = useRef();
  const { brandColor, shape2Color, shape3Color } = useSelector(state => ({
    brandColor: metronic.builder.selectors.getConfig(
      state,
      "colors.state.brand"
    ),
    shape2Color: metronic.builder.selectors.getConfig(
      state,
      "colors.base.shape.2"
    ),
    shape3Color: metronic.builder.selectors.getConfig(
      state,
      "colors.base.shape.3"
    )
  }));

  const data = useMemo(
    () => ({
      labels: typeof xAxis === 'undefined' ? [6, 5, 4, 3, 2, 1, 0].map(item =>
        moment()
          .subtract(item, "d")
          .format("D MMM")
      ) : xAxis.map(item => moment(item).format("D MMM")),
      datasets: [
        {
          lineTension: 0,
          label: label,
          fill: false,
          data: total,
          borderWidth: 1,
          backgroundColor: backgroundColor,

          borderColor: borderColor
        }
      ]
    }),
    [brandColor, total]
  );

  useEffect(() => {
    // For more information about the chartjs, visit this link
    // https://www.chartjs.org/docs/latest/getting-started/usage.html



    var config = {
      type: "line",
      data: data,
      options: {
        responsive: false,
        tooltips: {
          mode: "index",
          intersect: false
        },
        hover: {
          mode: "nearest",
          intersect: true
        },
        plugins : {
          display : false
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Week"
              }
            }
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Count"
              }
            }
          ]
        }
      }
    };

    const chart1 = new Chart(ref1.current, config);

    return () => {
      chart1.destroy();
    };
  }, [data, brandColor, shape2Color, shape3Color]);

  return (
    <div className="kt-widget12">
      <div className="kt-widget12__chart" style={{ height: "250px" }}>
        <canvas
          ref={ref1}
          width={500}
          height={312}
          id="kt_chart_order_statistics"
        />
      </div>
    </div>
  );
}
