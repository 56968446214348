import axios from "axios";

import { dev_endpont, prodcution_endpoint } from "../../endpoint";

export const URL =
  process.env.NODE_ENV === "development"
    ? `${dev_endpont}/campaign`
    : `${prodcution_endpoint}/campaign`;

export function getApi() {
  return axios.get(URL);
}

export function deleteApi(id) {
  return axios.delete(`${URL}/delete/${id}`);
}

export function editApi(id, data) {
  return axios.patch(`${URL}/update/${id}`, data);
}

export function createApi(data) {
  return axios.post(`${URL}/create`, data);
}

export function detailApi(id) {
  return axios.get(`${URL}/detail/${id}`);
}

export function editExpire(id, data) {
  return axios.patch(`${URL}/update/expire/${id}`, data);
}

export function reUpdate(id) {
  return axios.patch(`${URL}/reupdate/${id}`);
}


// export function getAdsLocationPoint(id) {
//   return axios.get(`${URL}/location_point/${id}`)
// }

export function getChartPoint(id) {
  return axios.get(`${URL}/detail/chart_point/${id}`)
}

export function getAdsLocation(id) {
  return axios.get(`${URL}/detail/ads_locations/${id}`)
}

export function getCount(id) {
  return axios.get(`${URL}/detail/count/${id}`)
}