import * as actionTypes from "../type/campaign";
import { updateObject } from "../shared/utility";

const initialState = {
  campaigns: [],
  loading: true,
  error: null
};

const fetchCampaignStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchCampaignSuccess = (state, action) => {
  return updateObject(state, {
    campaigns: action.campaigns,
    loading: false
  });
};

const fetchCampaignFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_CAMPAIGNS_START:
      return fetchCampaignStart(state, action);
    case actionTypes.FETCH_CAMPAIGNS_SUCCESS:
      return fetchCampaignSuccess(state, action);
    case actionTypes.FETCH_CAMPAIGNS_FAIL:
      return fetchCampaignFail(state, action);
    default:
      return state;
  }
};

export default reducer;
