import * as actionTypes from "../type/source_app";
import { updateObject } from "../shared/utility";

const initialState = {
  source_apps: [],
  loading: true,
  error: null
};

const fetchSourceStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const fetchSourceSuccess = (state, action) => {
  return updateObject(state, {
    source_apps: action.source_apps,
    loading: false
  });
};

const fetchSourceFail = (state, action) => {
  return updateObject(state, { loading: false, error: action.error });
};

const setSourceApp = (state, action) => {
  console.log(state);
  return updateObject(state, {
    source_apps: state.source_apps.concat([action.payload])
  });
};

const getSourceApp = (state, action) => {
  return updateObject(state, { source_apps: action.payload });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_SOURCE_APPS_START:
      return fetchSourceStart(state, action);
    case actionTypes.FETCH_SOURCE_APPS_SUCCESS:
      return fetchSourceSuccess(state, action);
    case actionTypes.FETCH_SOURCE_APPS_FAIL:
      return fetchSourceFail(state, action);
    case actionTypes.SET_SOURCE_APPS:
      return setSourceApp(state, action);
    case actionTypes.GET_SOURCE_APPS:
      return getSourceApp(state, action);
    default:
      return state;
  }
};

export default reducer;
