import { put } from "redux-saga/effects";

import axios from "axios";
import * as actions from "../actions/target";
import { URL } from "../axios/targetApi";

export function* fetchTargetSaga(action) {
  yield put(actions.fetchTargetStart());
  try {
    const { data } = yield axios.get(URL);
    yield put(actions.fetchTargetSuccess(data));
  } catch (error) {
    yield put(actions.fetchTargetFail("error"));
  }
}
