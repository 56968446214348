export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",

  AUTH_CHECK_STATE: "AUTH_CHECK_STATE",
  AUTH_USER: "AUTH_USER",
  AUTH_START: "AUTH_START",
  AUTH_SUCCESS: "AUTH_SUCCESS",
  AUTH_FAIL: "AUTH_FAIL",
  AUTH_CHECK_TIMEOUT: "AUTH_CHECK_TIMEOUT",
  AUTH_INITIATE_LOGOUT: "AUTH_INITIATE_LOGOUT",
  AUTH_LOGOUT: "AUTH_LOGOUT"
};

