import React from "react";
import TextField from "@material-ui/core/TextField";

const Input = ({ field, form, ...props }) => {
  return (
    <div style={{ margin: "1rem 0" }}>
      <label>{props.label}</label>
      <TextField
        {...field}
        {...props}
        variant="outlined"
        style={{ marginTop: "0px" }}
      />
    </div>
  );
};

export default Input;
