export const FETCH_LOCATIONS = "FETCH_LOCATIONS";
export const FETCH_LOCATIONS_START = "FETCH_LOCATIONS_START";
export const FETCH_LOCATIONS_SUCCESS = "FETCH_LOCATIONS_SUCCESS";
export const FETCH_LOCATIONS_FAIL = "FETCH_LOCATIONS_FAIL";

export const CREATE_LOCATIONS = "CREATE_LOCATIONS";
export const SET_LOCATIONS = "SET_LOCATIONS";


export const GET_LOCATIONS = "GET_LOCATIONS"
