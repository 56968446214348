/* eslint-disable no-restricted-imports */
import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { makeStyles } from "@material-ui/core/styles";

import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import InputField from '../../content/Input/input'
const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 500
  },
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));
const AddUserDialog = props => {
  const [data, setData] = useState({});
  const { addUserHandler, fields } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  const [switchState, setSwitchState] = React.useState({
    addMultiple: false
  });

  const handleSwitchChange = name => event => {
    setSwitchState({ ...switchState, [name]: event.target.checked });
  };

  const MyInput = ({ field, form, ...props }) => {
    return <TextField {...field} {...props} />;
  };

  const resetSwitch = () => {
    setSwitchState({ addMultiple: false });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    resetSwitch();
  };

  const validateField = value => {
    console.log("Pros from validdate", value);
    let error;
    if (value === "") {
      error = "Required!";
    }
    return error;
  };

  const handleAdd = event => {
    if (data.password === data.cpassword || data.password === undefined) {
      console.log("Add Data", data);
      addUserHandler(data);
      setData("");
      switchState.addMultiple ? setOpen(true) : setOpen(false);
    } else {
      alert("Password Did Not Match");
    }
  };

  const handleChange = name => ({ target: { value } }) => {
    setData({ ...data, [name]: value });
  };

  return (
    <div>
      <Tooltip title="Add">
        <div>
          <Fab
            onClick = {handleClickOpen}
            variant="extended"
            size="small"
            color="primary"
            aria-label="add"
            className={classes.margin}
          >
            <AddIcon className={classes.extendedIcon} />
            Add
          </Fab>
        </div>
        {/* <IconButton aria-label="add" onClick={handleClickOpen}>
            <Fab
              variant="extended"
              size="medium"
              color="primary"
              aria-label="Add"
              className={classes.margin}
            >
              <AddIcon className={classes.extendedIcon} />
              Add
            </Fab>
        </IconButton> */}
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add </DialogTitle>
        <>
          <Formik
            initialValues={fields.reduce((obj, cur, i) => {
              return { ...obj, [cur.name]: cur.value };
            }, {})}
            onSubmit={(values, { setSubmitting }) => {
              if (
                values.password === values.cpassword ||
                values.password === undefined
              ) {
                addUserHandler(values);
                setData("");
                switchState.addMultiple ? setOpen(true) : setOpen(false);
              } else {
                alert("Password Did Not Match");
              }
              setSubmitting(false);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              initialValues,
              isSubmitting
            }) => (
              <Form style={{ width: "500px" }}>
                <DialogContent>
                  {fields.map(i => {
                    return (
                      <>
                        <Field
                          name={i.name}
                          label={i.label}
                          type={i.type}
                          margin="dense"
                          fullWidth
                          component={InputField}
                          validate={validateField}
                        />
                        {touched[i.name] && errors[i.name] ? (
                          <div style={{ color: "red" }}>{errors[i.name]}</div>
                        ) : null}
                      </>
                    );
                  })}
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="primary">
                    Cancel
                  </Button>
                  <Button type="submit" color="primary" disabled={isSubmitting}>
                    Add
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </>
      </Dialog>
    </div>
  );
};

AddUserDialog.propTypes = {
  addUserHandler: PropTypes.func.isRequired
};

export default AddUserDialog;
